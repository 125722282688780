<div
    class="avatar avatar-{{ size }}"
    [class.bg-icon]="!avatar?.prioritiseIcon"
    [ngClass]="avatar?.isUnknown ? 'bg-danger text-danger-contrast' : ''"
    [attr.data-cy]="'avatar-' + avatar?.text"
    [style.backgroundColor]="
        !avatar?.isUnknown && !avatar?.icon && !avatar?.avatarUrl
            ? (avatar?.text | textToHsl)
            : null
    "
    [class.text-body]="
        (!avatar?.isUnknown && avatar?.text) || !avatar?.prioritiseIcon
    "
>
    <div
        *ngIf="highlightBorder"
        class="highlight-border highlight-border--rounded highlight-border--{{
            highlightBorder
        }}"
    ></div>

    <span
        *ngIf="
            !avatar?.prioritiseIcon && avatar?.text && !avatar?.isUnknown;
            else altIconTmpl
        "
        class="avatar-alt-initials"
        >{{ initials }}</span
    >

    <ng-template #altIconTmpl>
        <wdx-icon-container [size]="size!">
            <wdx-icon
                class="avatar-alt-icon"
                [class.text-gray-500]="!avatar?.isUnknown"
                [icon]="altIcon"
            ></wdx-icon>
        </wdx-icon-container>
    </ng-template>

    <ng-template #altText>
        <span class="avatar-alt-text">{{ avatar?.text }}</span>
    </ng-template>

    <div
        class="avatar-image"
        [style.background-image]="
            avatar?.avatarUrl ? 'url(' + avatar?.avatarUrl + ')' : ''
        "
    ></div>
</div>
