import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WINDOW } from '@wdx/shared/utils';

interface KaminoEnvironment {
    code: string;
    name: string;
    url: string;
}

@Component({
    selector: 'kmo-environment-selector',
    standalone: true,
    imports: [
        CommonModule,
        WdxDropdownModule,
        WdxIconModule,
        NgbDropdownModule,
        PushPipe,
    ],
    templateUrl: './environment-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KaminoEnvironmentSelectorComponent {
    private _window = inject(WINDOW);

    @Input() currentEnvironment?: string;

    public readonly environments: KaminoEnvironment[] = [
        {
            code: 'ci',
            name: 'CI',
            url: 'https://ops-ci.clmi-dev.io',
        },
        {
            code: 'demo',
            name: 'Demo',
            url: 'https://ops-demo.clmi-dev.io',
        },
        {
            code: 'development',
            name: 'Development',
            url: 'https://ops-dev.clmi-dev.io',
        },
        {
            code: 'hotfixch',
            name: 'HotFix (CH)',
            url: 'https://ops-hotfix.ch.clmi.io',
        },
        {
            code: 'next',
            name: 'Next',
            url: 'https://ops-next.clmi-dev.io',
        },
        {
            code: 'productionch',
            name: 'Production (CH)',
            url: 'https://ops-prod.ch.clmi.io',
        },
        {
            code: 'support',
            name: 'Production Support',
            url: 'https://ops-support.clmi-dev.io',
        },
        {
            code: 'staging',
            name: 'Staging',
            url: 'https://ops-staging.clmi-staging.io',
        },
        {
            code: 'test',
            name: 'Test',
            url: 'https://ops-test.clmi-dev.io',
        },
        {
            code: 'uatch',
            name: 'UAT (CH)',
            url: 'https://ops-uat.ch.clmi.io',
        },
    ];

    switchEnvironment(environment: KaminoEnvironment) {
        this._window.location.href = environment.url;
    }
}
