import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    HEADER_CODE,
    HEADER_EVENT_TYPE,
    HEADER_FEATURE,
} from '@wdx/kamino/utils';
import { EVENT, FILE } from '@wdx/kamino/utils/constants';
import { KaminoRecentRecordsService } from '@wdx/kamino/utils/services';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { Observable, catchError, tap } from 'rxjs';

@Injectable()
export class ToastrInterceptor implements HttpInterceptor {
    private wdxToastService = inject(WdxToastService);
    private recentRecordsService = inject(KaminoRecentRecordsService);

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap<HttpEvent<unknown>>((httpEvent: HttpEvent<unknown>) => {
                if (httpEvent instanceof HttpResponse) {
                    let message = '';
                    let severity = Severity.Success;
                    const EVENT_TYPE = request.headers.get(HEADER_EVENT_TYPE);
                    const feature = request.headers.get(HEADER_FEATURE);
                    const code = request.headers.get(HEADER_CODE);
                    let type = '';
                    let localStorageKey = EVENT;

                    if (request.method === 'DELETE') {
                        if (request.url.endsWith('/local')) {
                            type = 'delete';
                            message = 'Record successfully reset';
                        } else {
                            type = 'delete';
                            message = 'Record deleted successfully';
                        }
                    }

                    if (
                        request.method === 'PATCH' &&
                        request.url.endsWith('/promote')
                    ) {
                        type = 'patch';
                        message = 'Record successfully promoted';
                    }

                    if (request.method === 'PUT' && EVENT_TYPE) {
                        type = EVENT_TYPE;
                        message = `Record ${EVENT_TYPE} successfully`;
                    }

                    if (request.url.includes('/zip')) {
                        localStorageKey = FILE;
                        type = 'export';
                        message = `Export: record exported successfully`;
                    }

                    if (request.url.includes('/import')) {
                        localStorageKey = FILE;
                        let event = '';

                        if (request.method === 'POST') {
                            type = 'upload';
                        }

                        if (request.url.includes('/abort')) {
                            type = 'abort';
                            event = 'aborted';
                            severity = Severity.Info;
                        }

                        if (request.url.includes('/execute')) {
                            type = 'execute';
                            event = 'execute';
                        }

                        if (event) {
                            message = `Import: record ${event} successfully`;
                        }
                    }

                    if (feature && code) {
                        this.recentRecordsService.setRecentRecord(
                            localStorageKey,
                            {
                                url: request.url,
                                type,
                                feature: feature,
                                code: code,
                            }
                        );
                    }

                    if (message) {
                        this.wdxToastService.show({
                            body: [message],
                            severity,
                        });
                    }
                }

                return httpEvent;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status >= 400) {
                    const MESSAGE =
                        error?.error?.detail ||
                        error?.error?.title ||
                        (typeof error?.error === 'string' ? error?.error : '');

                    this.wdxToastService.show({
                        body: [MESSAGE],
                        severity: Severity.Danger,
                        autohide: false,
                    });
                }

                throw error;
            })
        );
    }
}
