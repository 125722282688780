import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { IImport } from './import.interface';

const IMPORT = 'import';

@Injectable({
    providedIn: 'root',
})
export class ImportService {
    private httpClient = inject(HttpClient);
    private configService = inject(ConfigService);

    /**
     *
     * @param {string} id - This is the id of the import to get
     * @returns {Observable<IImport>}
     */
    getImportById(id: string): Observable<IImport> {
        return this.httpClient.get<IImport>(
            `${this.configService.config.apiDomain}/${IMPORT}/${id}`,
            {}
        );
    }

    /**
     * This will upload the a file to the imports/upload endpoint
     *
     * @param {File} file - This is the file that needs to be uploaded
     * @returns {Observable<HttpEvent<any>>} - so that the upload progress can be tracked
     */
    sendData(
        file: File,
        path = `${IMPORT}/upload`
    ): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append(file.name, file);

        return this.httpClient.post<HttpEvent<any>>(
            `${this.configService.config.apiDomain}/${path}`,
            formData,
            {
                reportProgress: true,
                observe: 'events',
            }
        );
    }

    /**
     *
     * @param {string} id - This is the id of the import to execute
     * @returns {Observable<unknown>}
     */
    execute(id: string): Observable<unknown> {
        return this.httpClient.patch<unknown>(
            `${this.configService.config.apiDomain}/${IMPORT}/${id}/execute`,
            {}
        );
    }

    /**
     *
     * @param {string} id - This is the id of the import to abort
     * @returns {Observable<unknown>}
     */
    abort(id: string): Observable<unknown> {
        return this.httpClient.patch<unknown>(
            `${this.configService.config.apiDomain}/${IMPORT}/${id}/abort`,
            {}
        );
    }

    /**
     *
     * @param {string} id - This is the id of the import to downloaded
     * @returns {Observable<unknown>}
     */
    getFile(importInfo: IImport): Observable<HttpResponse<Blob>> {
        return this.httpClient.get<Blob>(
            `${this.configService.config.apiDomain}/${IMPORT}/${importInfo.id}/file`,
            {
                responseType: 'blob' as 'json',
                observe: 'response',
                reportProgress: true,
            }
        );
    }
}
