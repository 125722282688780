import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';
import { PushPipe } from '@ngrx/component';
import { SystemDataService } from '@wdx/kamino/api-service';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxToastComponent } from '@wdx/shared/components/wdx-toast';
import { combineLatestWith, filter } from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [
        NgIf,
        RouterOutlet,
        WdxSpinnerModule,
        PushPipe,
        WdxToastComponent,
    ],
    selector: 'ops-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private authService = inject(AuthService);
    private systemDataService = inject(SystemDataService);

    isAppReady$ = this.authService.isAuthenticated$.pipe(
        combineLatestWith(
            this.systemDataService.isLoading$.pipe(filter((res) => !res))
        )
    );
}
