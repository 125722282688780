<kmo-modal-wrapper>
    <ng-container title>File Import</ng-container>
    <ng-container body>
        <kamino-file-uploader
            class="py-4 px-2 d-block"
            [acceptedFileTypes]="acceptedFileTypes"
            (fileEvent)="onFileEvent($event)"
        ></kamino-file-uploader>
    </ng-container>
</kmo-modal-wrapper>
