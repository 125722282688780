import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FILE } from '@wdx/kamino/utils/constants';
import { KaminoRecentRecordsService } from '@wdx/kamino/utils/services';
import { DOWNLOAD, Severity } from '@wdx/shared/utils';
import { Observable, map, tap } from 'rxjs';
import { IImport, ImportEventType } from './import.interface';
import { ImportService } from './import.service';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { FeatureDataService } from '../feature/feature-data.service';

@Injectable({
    providedIn: 'root',
})
export class ImportFacade {
    private importService = inject(ImportService);
    private recentRecordsService = inject(KaminoRecentRecordsService);
    private toastService = inject(WdxToastService);
    private featureDataService = inject(FeatureDataService);

    /**
     *
     * @param {File} file - This is the zip file that needs to be uploaded
     *
     * @returns {Observable<IImport>}
     */
    getImportById$(id: string): Observable<IImport> {
        return this.importService.getImportById(id);
    }

    /**
     *
     * @param {File} file - This is the  file that needs to be uploaded
     *
     * @returns {Observable<HttpEvent<IImport>>}
     */
    sendData$(file: File, path: string): Observable<HttpEvent<IImport>> {
        return this.importService.sendData(file, path).pipe(
            tap((res: HttpEvent<IImport>) => {
                if (res instanceof HttpResponse) {
                    if (res.body) {
                        this.setRecentEvent((res.body as IImport).id, 'upload');
                    } else {
                        this.toastService.show({
                            body: ['Import job was successfully completed.'],
                            severity: Severity.Success,
                        });
                        this.featureDataService.getFeatureData();
                    }
                }
            })
        );
    }

    /**
     *
     * @param {ImportEventType} event
     * @param {string} id
     * @returns {Observable<unknown>}
     */
    importEvent$(event: ImportEventType, id: string): Observable<unknown> {
        const IMPORT_EVENT =
            event === 'execute'
                ? this.importService.execute(id)
                : this.importService.abort(id);

        return IMPORT_EVENT.pipe(
            map((res) => {
                this.setRecentEvent(id, event);
                return res;
            })
        );
    }

    /**
     *
     * @param {IImport} importInfo - This is the info of the file import
     *
     * @returns {Observable<HttpResponse<Blob>>}
     */
    getFileDownload$(importInfo: IImport): Observable<HttpResponse<Blob>> {
        return this.importService.getFile(importInfo).pipe(
            map((res) => {
                if (res.status === 200) {
                    this.setRecentEvent(importInfo.id, 'download');
                    DOWNLOAD(
                        `${importInfo.sourceEnvironment}-${importInfo.targetTenant}-${importInfo.created}`,
                        res
                    );
                }

                return res;
            })
        );
    }

    private setRecentEvent(code: string, type: string): void {
        this.recentRecordsService.setRecentRecord(FILE, {
            feature: 'import',
            code,
            type,
        });
    }
}
