<wdx-dropdown ngbDropdown display="dynamic" #dropdown="ngbDropdown">
    <button
        ngbDropdownToggle
        class="btn fw-normal d-flex align-items-center text-primary-contrast"
    >
        <span
            class="ms-1 fw-bold text-capitalize"
            data-cy="kmo-ui-tenant-selected"
            >{{ currentEnvironment }}</span
        >
        <wdx-icon
            class="ms-2 toggle-icon"
            icon="chevron-down"
            [rotate]="dropdown.isOpen() ? 180 : 0"
        ></wdx-icon>
    </button>
    <wdx-dropdown-menu ngbDropdownMenu>
        <button
            class="d-flex"
            *ngFor="let env of environments; let i = index"
            ngbDropdownItem
            (click)="switchEnvironment(env)"
        >
            {{ env.name }}

            <wdx-icon
                *ngIf="currentEnvironment === env?.code"
                class="ms-auto"
                icon="check"
            ></wdx-icon>
        </button>
    </wdx-dropdown-menu>
</wdx-dropdown>
