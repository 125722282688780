import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withRouterConfig } from '@angular/router';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { APP_CONFIG, TenantInterceptor } from '@wdx/kamino/api-service';
import { WINDOW_PROVIDERS } from '@wdx/shared/utils';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { ToastrInterceptor } from './app/shared/interceptors/toastr/toastr.interceptor';

fetch('/assets/config.json')
    .then((res) => res.json())
    .then((config) => {
        bootstrapApplication(AppComponent, {
            providers: [
                provideRouter(
                    appRoutes,
                    withRouterConfig({ paramsInheritanceStrategy: 'always' }),
                ),
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthHttpInterceptor,
                    multi: true,
                },
                { provide: APP_CONFIG, useValue: config },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: TenantInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ToastrInterceptor,
                    multi: true,
                },
                importProvidersFrom(
                    AuthModule.forRoot({
                        domain: config.authDomain,
                        clientId: config.clientId,
                        authorizationParams: {
                            audience: config.audience,
                            scope: 'access:user',
                            redirect_uri: window.location.origin,
                        },
                        useRefreshTokens: true,
                        errorPath: '/error',
                        httpInterceptor: {
                            allowedList: [
                                {
                                    uri: `${config.apiDomain}/*`,
                                    tokenOptions: {
                                        authorizationParams: {
                                            audience: config.audience,
                                            scope: 'access:user',
                                        },
                                    },
                                },
                            ],
                        },
                    }),
                ),
                provideHttpClient(withInterceptorsFromDi()),
                WINDOW_PROVIDERS,
            ],
        }).catch((err) => console.error(err));
    });
